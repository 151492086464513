import React from "react"
import marked from "marked"
import cx from "classnames"
import { graphql, Link } from "gatsby"
import Layout from "components/Layout"
import Container from "components/Container"
import Title from "components/Title"
import Img from "gatsby-image/withIEPolyfill"
import altStyles from "./WhatWeDo.module.css"
import styles from "./BlogPost.module.css"
import * as Icons from "components/Icons"
import HubspotForm from "components/HubspotForm"
import GoogleMap from "../components/GoogleMap"
import Blocks from "components/Blocks"

const icons = {
  linkedin: Icons.Linkedin,
  twitter: Icons.Twitter,
  facebook: Icons.Facebook,
  github: Icons.Github,
}

// eslint-disable-next-line
export const LandingPage = ({ data, socials, html, blogPosts, casestudies, services, solutions, ...props }) => (
  <Layout {...props} {...data} {...blogPosts}>
    {data.layout === 'new' ? (
      <div>
        <div className={data.imagePosition === 'background' ? altStyles.bgBannerImage : null} style={data.imagePosition === 'background' ? {backgroundImage: `url(${data.image.publicURL})`} : null}>
        <Container className={altStyles.wrapper}>
        {data.bannerTitle ? (
            <div>
            <Title className={altStyles.bannertitle}>{data.title}</Title>
            <h2 className={`${altStyles.title} ${altStyles.titlesmall}`}>
            <span dangerouslySetInnerHTML={{ __html: data.bannerTitle || ""}} />
            </h2>
            <div className={altStyles.description} dangerouslySetInnerHTML={{ __html: marked(data.description || "") }} />
            <Link className={altStyles.bannerbutton} to={`${data.bannerButtonUrl}`}>{data.bannerButtonText}</Link>
          </div>
        ) : (
          <Title className={altStyles.title}>
            <span>{data.title}</span>
          </Title>
        )}
        {data.image && data.imagePosition !== 'background' ? (
          <div className={altStyles.image}>
            {data.image.extension === "svg" ? (
              <img alt={data.bannerImageAltText ? data.bannerImageAltText : data.name} title={data.name} src={data.image.publicURL} loading="lazy" width="560" height="420"/>
            ) : data.image.childImageSharp ? (
              <Img
                loading="lazy"
                objectFit="contain"
                alt={data.bannerImageAltText ? data.bannerImageAltText : data.name}
                title={data.name}
                fluid={data.image.childImageSharp.fluid}
              />
            ) : null}
          </div>
        ) : null}
        </Container>
      </div>
      {(data.blocks || []).map((block, i) => {
        const Elem = Blocks[block.type]
        return Elem ? <Elem key={i} 
         {...block}
         {...blogPosts}
         casestudies = {casestudies}
         pages = {block.pageType === 'services' ? services : block.pageType === 'solutions' ? solutions : null}
         /> : null }
      )}
  
      {data.form && data.portalId ? (
        <div className={altStyles.grayBackground} id="contact-form">
          <Container>
            <div className={altStyles.titleContainer}>
              <p className={styles.title}><span className={altStyles.preTitle}>Contact Us</span>{data.formTitle}</p>
            </div>
            {data.mapAddress ? (
                <div className={altStyles.locationContainer}>
                  <div className={altStyles.locationInformation}>
                    <Link className={altStyles.logo} to="/">
                      OpsGuru
                      {Icons.Logo}
                    </Link>
                    <div className={altStyles.description} dangerouslySetInnerHTML={{ __html: marked(data.formDescription || "") }} />
                  </div>
                  {data.mapAddress || data.formDescription ? (
                    <div className={altStyles.locationMap}>
                      <GoogleMap address={data.mapAddress} title={data.title} />
                    </div>
                  ) : null}
                </div>
            ) : null}
            <HubspotForm id={data.form} portalId={data.portalId} />
          </Container>
        </div>
      ) : null}
      </div>
    ) : data.layout === 'survey' ? (
      <div>
        <div className={data.imagePosition === 'background' ? altStyles.bgBannerImage : null} style={data.imagePosition === 'background' ? {backgroundImage: `url(${data.image.publicURL})`} : null}>
          <Container className={cx(altStyles.wrapper, styles.wrapperSurvey)}>
            <div>
              <Title className={`${altStyles.title} ${altStyles.titlesmall}`}>
                <span dangerouslySetInnerHTML={{ __html: data.title || ""}} />
              </Title>
              <div className={altStyles.description} dangerouslySetInnerHTML={{ __html: marked(data.description || "") }} />
            </div>
          {data.image && data.imagePosition !== 'background' ? (
            <div className={altStyles.image}>
              {data.image.extension === "svg" ? (
                <img alt={data.name} title={data.name} src={data.image.publicURL} loading="lazy" width="560" height="420"/>
              ) : data.image.childImageSharp ? (
                <Img
                  loading="lazy"
                  objectFit="contain"
                  alt={data.name}
                  title={data.name}
                  fluid={data.image.childImageSharp.fluid}
                />
              ) : null}
            </div>
          ) : null}
          </Container>
        </div>

        <div className={styles.mainSurvey}>
          <Container className={altStyles.landingWrap}>
            <div className={cx(styles.html, styles.mainHtml)} dangerouslySetInnerHTML={{ __html: html }} />
          </Container>
        </div>

        {(data.blocks || []).map((block, i) => {
          const Elem = Blocks[block.type]
          return Elem ? <Elem key={i} 
           {...block}
           {...blogPosts}
           casestudies = {casestudies}
           pages = {block.pageType === 'services' ? services : block.pageType === 'solutions' ? solutions : null}
           /> : null }
        )}

        {data.form ? (
          <HubspotForm id={data.form} portalId={data.portalId} />
        ) : null}
      </div>
    ) :
      <div>
        <Container className={styles.post}>
          <h2>{data.title}</h2>
          <div className={styles.html} dangerouslySetInnerHTML={{ __html: html }} />
        </Container>
        {data.form ? (
          <HubspotForm id={data.form} portalId={data.portalId} />
        ) : null}

        <Container className={cx(altStyles.landingWrap, styles.socials)}>
          {Object.keys(socials).map((social) => (
            <a key={social} href={socials[social]} target="_blank" rel="noopener noreferrer">
              {icons[social]}
            </a>
          ))}
        </Container>
      </div>
    }
  </Layout>
)

// eslint-disable-next-line
export const LandingPageWrapper = (props) => (
  <LandingPage
    {...props}
    socials={props.data.socials.frontmatter.socials}
    html={props.data.markdownRemark.html}
    data={props.data.markdownRemark.frontmatter}
    blogPosts={props.data.blogPosts}
    casestudies={props.data.casestudies.edges}
    services={props.data.services.edges}
    solutions={props.data.solutions.edges}
  />
)

export default LandingPageWrapper

export const pageQuery = graphql`
  query LandingPage($id: String) {
    socials: markdownRemark(frontmatter: { templateKey: { eq: "main" } }) {
      frontmatter {
        socials {
          linkedin
          twitter
          facebook
          github
        }
      }
    }
    services: allMarkdownRemark(
      filter: {
        frontmatter: { 
          templateKey: { eq: "whatwedo" },
          menuOrder: { ne: null },
          url: { 
            in: [
              "insights-from-data",
              "kubernetes-enablement",
              "application-modernization",
              "cloud-adoption",
              "cloud-security",
              "cloud-assessment",
              "managed-cloud"
            ]
          }
        } 
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            url
            title
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    solutions: allMarkdownRemark(
      filter: {
        frontmatter: { 
          templateKey: { in: ["whatwedo", "industries"] },
          menuOrder: { ne: null },
          url: { 
            in: [
              "cloud-spend-optimization",
              "cloud-launchpad",
              "startup-smb",
              "clear-path-forward",
              "isv-saas-cloud",
              "enterprise-cloud-solutions",
              "public-sector-cloud-adoption"
            ]
          }
        } 
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            url
            title
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    casestudies: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "successstories" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            id
            url
            title
            mainTitle
            date(formatString: "MMM Do, YYYY")
            quote
            logo {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        seo {
          title
          description
          canonical
          image {
            childImageSharp {
              fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        title
        description
        bannerTitle
        bannerButtonText
        bannerButtonUrl
        formTitle
        form
        portalId
        layout
        imagePosition
        formDescription
        mapAddress
        bannerImageAltText
        image {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        blocks {
          type
          title
          description
          buttonText
          buttonUrl
          subTitle
          layout
          bgColor
          imageWidth
          imagePosition
          arrowsBetweenCards
          paddingTop
          pageType
          externalLink
          imageAltText
          image {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          secondImage {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          bigListList {
            title
            description
          }
          gridWithImageList {
            title
            description
          }
          imageAndListList
          gridWithIconsList {
            title
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          bigListWithBackgroundsList {
            title
            description
          }
          gridWithImageAndList {
            title
            description
          }
          cards {
            title
            text
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          gridWithLeftedImage {
            title
            text
          }
          gradientedGridList {
            title
            text
          }
          iconsWithBackgroundsList {
            title
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          listWithImageAndDescription {
            title
            description
          }
          gridWithIconsAndImageList {
            title
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imagesGrid {
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          partners {
            link
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          textWithImage {
            title
            subtitle
            text
            buttonText
            buttonUrl
            imageAltText
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          textWithCards {
            title
            preTitle
            description
            text
            buttonText
            buttonUrl
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 150, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          iconsGridList {
            title
            subtitle
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          blobIcons {
            title
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          contentSlider {
            description
            title
            link
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          contentWithList {
            secondTitle
            list {
              label
              link
            }
          }
        }
      }
    }
    blogPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: { templateKey: { eq: "blog" } },
        isFuture: { eq: false }
      }
      limit: 4
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            id
            url
            title
            tags
            date(formatString: "MMM Do, YYYY")
            description
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 338) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
